import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67edcf79 = () => interopDefault(import('../pages/elements/index.vue' /* webpackChunkName: "pages/elements/index" */))
const _bfc16e60 = () => interopDefault(import('../pages/ProductCard.vue' /* webpackChunkName: "pages/ProductCard" */))
const _0ce079de = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _38804871 = () => interopDefault(import('../pages/test2.vue' /* webpackChunkName: "pages/test2" */))
const _ad5a8786 = () => interopDefault(import('../pages/blog/blogResult.vue' /* webpackChunkName: "pages/blog/blogResult" */))
const _21a40924 = () => interopDefault(import('../pages/blog/classic.vue' /* webpackChunkName: "pages/blog/classic" */))
const _5862c3fa = () => interopDefault(import('../pages/blog/filteredAge.vue' /* webpackChunkName: "pages/blog/filteredAge" */))
const _77982933 = () => interopDefault(import('../pages/blog/grid-sidebar.vue' /* webpackChunkName: "pages/blog/grid-sidebar" */))
const _4d454216 = () => interopDefault(import('../pages/blog/listing.vue' /* webpackChunkName: "pages/blog/listing" */))
const _03c49d66 = () => interopDefault(import('../pages/blog/masonry-sidebar.vue' /* webpackChunkName: "pages/blog/masonry-sidebar" */))
const _272cad94 = () => interopDefault(import('../pages/elements/accordions.vue' /* webpackChunkName: "pages/elements/accordions" */))
const _bc07f9a4 = () => interopDefault(import('../pages/elements/banners.vue' /* webpackChunkName: "pages/elements/banners" */))
const _ed4abe5e = () => interopDefault(import('../pages/elements/blog-posts.vue' /* webpackChunkName: "pages/elements/blog-posts" */))
const _aa752230 = () => interopDefault(import('../pages/elements/buttons.vue' /* webpackChunkName: "pages/elements/buttons" */))
const _0c9ca1f6 = () => interopDefault(import('../pages/elements/categories.vue' /* webpackChunkName: "pages/elements/categories" */))
const _68c15d12 = () => interopDefault(import('../pages/elements/cta.vue' /* webpackChunkName: "pages/elements/cta" */))
const _393931ce = () => interopDefault(import('../pages/elements/icon-boxes.vue' /* webpackChunkName: "pages/elements/icon-boxes" */))
const _34953d22 = () => interopDefault(import('../pages/elements/portfolio.vue' /* webpackChunkName: "pages/elements/portfolio" */))
const _7ffe368d = () => interopDefault(import('../pages/elements/products.vue' /* webpackChunkName: "pages/elements/products" */))
const _b2ee27b2 = () => interopDefault(import('../pages/elements/tabs.vue' /* webpackChunkName: "pages/elements/tabs" */))
const _0cd97c5d = () => interopDefault(import('../pages/elements/testimonials.vue' /* webpackChunkName: "pages/elements/testimonials" */))
const _033bcb04 = () => interopDefault(import('../pages/elements/titles.vue' /* webpackChunkName: "pages/elements/titles" */))
const _56751c98 = () => interopDefault(import('../pages/elements/typography.vue' /* webpackChunkName: "pages/elements/typography" */))
const _b64e5cc8 = () => interopDefault(import('../pages/elements/video-banners.vue' /* webpackChunkName: "pages/elements/video-banners" */))
const _79f04804 = () => interopDefault(import('../pages/pages/404.vue' /* webpackChunkName: "pages/pages/404" */))
const _247eb053 = () => interopDefault(import('../pages/pages/about.vue' /* webpackChunkName: "pages/pages/about" */))
const _0bfe4e90 = () => interopDefault(import('../pages/pages/about-2.vue' /* webpackChunkName: "pages/pages/about-2" */))
const _63f73a10 = () => interopDefault(import('../pages/pages/aboutAlf.vue' /* webpackChunkName: "pages/pages/aboutAlf" */))
const _a174f2c6 = () => interopDefault(import('../pages/pages/activate.vue' /* webpackChunkName: "pages/pages/activate" */))
const _70691cd4 = () => interopDefault(import('../pages/pages/checkForgetPassword.vue' /* webpackChunkName: "pages/pages/checkForgetPassword" */))
const _5445320d = () => interopDefault(import('../pages/pages/coming-soon.vue' /* webpackChunkName: "pages/pages/coming-soon" */))
const _0210bbe6 = () => interopDefault(import('../pages/pages/contact.vue' /* webpackChunkName: "pages/pages/contact" */))
const _898368ea = () => interopDefault(import('../pages/pages/contact-2.vue' /* webpackChunkName: "pages/pages/contact-2" */))
const _6ef87edc = () => interopDefault(import('../pages/pages/faq.vue' /* webpackChunkName: "pages/pages/faq" */))
const _d9fe455c = () => interopDefault(import('../pages/pages/forgetPassword.vue' /* webpackChunkName: "pages/pages/forgetPassword" */))
const _20bcc34a = () => interopDefault(import('../pages/pages/info/index.vue' /* webpackChunkName: "pages/pages/info/index" */))
const _0dde2e2f = () => interopDefault(import('../pages/pages/login.vue' /* webpackChunkName: "pages/pages/login" */))
const _1d524866 = () => interopDefault(import('../pages/pages/new.vue' /* webpackChunkName: "pages/pages/new" */))
const _f01f500e = () => interopDefault(import('../pages/pages/places/index.vue' /* webpackChunkName: "pages/pages/places/index" */))
const _0a3834ef = () => interopDefault(import('../pages/pages/profile.vue' /* webpackChunkName: "pages/pages/profile" */))
const _322d7550 = () => interopDefault(import('../pages/pages/resetPassword.vue' /* webpackChunkName: "pages/pages/resetPassword" */))
const _65e0983a = () => interopDefault(import('../pages/shop/Card.vue' /* webpackChunkName: "pages/shop/Card" */))
const _62e7ac2a = () => interopDefault(import('../pages/shop/cart.vue' /* webpackChunkName: "pages/shop/cart" */))
const _32c27250 = () => interopDefault(import('../pages/shop/checkout.vue' /* webpackChunkName: "pages/shop/checkout" */))
const _25a38112 = () => interopDefault(import('../pages/shop/checkout2.vue' /* webpackChunkName: "pages/shop/checkout2" */))
const _79b9b67a = () => interopDefault(import('../pages/shop/complete.vue' /* webpackChunkName: "pages/shop/complete" */))
const _6f2e5bfa = () => interopDefault(import('../pages/shop/dashboard.vue' /* webpackChunkName: "pages/shop/dashboard" */))
const _541202c6 = () => interopDefault(import('../pages/shop/market.vue' /* webpackChunkName: "pages/shop/market" */))
const _780b1428 = () => interopDefault(import('../pages/shop/payment.vue' /* webpackChunkName: "pages/shop/payment" */))
const _09f6dfb4 = () => interopDefault(import('../pages/shop/products2.vue' /* webpackChunkName: "pages/shop/products2" */))
const _2f98a05d = () => interopDefault(import('../pages/shop/productSearch.vue' /* webpackChunkName: "pages/shop/productSearch" */))
const _6c16f962 = () => interopDefault(import('../pages/shop/wishlist.vue' /* webpackChunkName: "pages/shop/wishlist" */))
const _66296f1b = () => interopDefault(import('../pages/blog/mask/grid.vue' /* webpackChunkName: "pages/blog/mask/grid" */))
const _6869fd00 = () => interopDefault(import('../pages/blog/mask/masonry.vue' /* webpackChunkName: "pages/blog/mask/masonry" */))
const _2562f20e = () => interopDefault(import('../pages/brands/sidebar/advancedBrand.vue' /* webpackChunkName: "pages/brands/sidebar/advancedBrand" */))
const _1c8a26d8 = () => interopDefault(import('../pages/pages/account/address.vue' /* webpackChunkName: "pages/pages/account/address" */))
const _6195c82d = () => interopDefault(import('../pages/pages/account/changePass.vue' /* webpackChunkName: "pages/pages/account/changePass" */))
const _3160a19e = () => interopDefault(import('../pages/pages/account/orders.vue' /* webpackChunkName: "pages/pages/account/orders" */))
const _7939320d = () => interopDefault(import('../pages/pages/account/profile.vue' /* webpackChunkName: "pages/pages/account/profile" */))
const _9af09fe2 = () => interopDefault(import('../pages/pages/account/trackOrder.vue' /* webpackChunkName: "pages/pages/account/trackOrder" */))
const _1478a23c = () => interopDefault(import('../pages/pages/info/location.vue' /* webpackChunkName: "pages/pages/info/location" */))
const _070655f6 = () => interopDefault(import('../pages/pages/info/socialProfile.vue' /* webpackChunkName: "pages/pages/info/socialProfile" */))
const _5d52b6d0 = () => interopDefault(import('../pages/pages/places/advancedSearchResult.vue' /* webpackChunkName: "pages/pages/places/advancedSearchResult" */))
const _5a9a1075 = () => interopDefault(import('../pages/pages/places/findPlace.vue' /* webpackChunkName: "pages/pages/places/findPlace" */))
const _8bb8d59c = () => interopDefault(import('../pages/pages/places/placesResult.vue' /* webpackChunkName: "pages/pages/places/placesResult" */))
const _36d21563 = () => interopDefault(import('../pages/shop/category/boxed.vue' /* webpackChunkName: "pages/shop/category/boxed" */))
const _7f07e750 = () => interopDefault(import('../pages/shop/category/fullwidth.vue' /* webpackChunkName: "pages/shop/category/fullwidth" */))
const _48ccfb10 = () => interopDefault(import('../pages/shop/sidebar/advancedAttr.vue' /* webpackChunkName: "pages/shop/sidebar/advancedAttr" */))
const _4f46aa0c = () => interopDefault(import('../pages/shop/sidebar/advancedAttrAfterCategory.vue' /* webpackChunkName: "pages/shop/sidebar/advancedAttrAfterCategory" */))
const _b77a1710 = () => interopDefault(import('../pages/shop/sidebar/advancedBrand.vue' /* webpackChunkName: "pages/shop/sidebar/advancedBrand" */))
const _1eaf2583 = () => interopDefault(import('../pages/shop/sidebar/advancedProducts.vue' /* webpackChunkName: "pages/shop/sidebar/advancedProducts" */))
const _6e5e03e2 = () => interopDefault(import('../pages/shop/sidebar/advancedSubCategory.vue' /* webpackChunkName: "pages/shop/sidebar/advancedSubCategory" */))
const _afa6a0aa = () => interopDefault(import('../pages/shop/sidebar/shopFilter.vue' /* webpackChunkName: "pages/shop/sidebar/shopFilter" */))
const _513e5a63 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7b677413 = () => interopDefault(import('../pages/blog/single/default/_slug.vue' /* webpackChunkName: "pages/blog/single/default/_slug" */))
const _73dc2329 = () => interopDefault(import('../pages/blog/single/fullwidth/_slug.vue' /* webpackChunkName: "pages/blog/single/fullwidth/_slug" */))
const _5d3e436e = () => interopDefault(import('../pages/blog/single/sidebar/_slug.vue' /* webpackChunkName: "pages/blog/single/sidebar/_slug" */))
const _1eb6e56e = () => interopDefault(import('../pages/blog/grid/_type.vue' /* webpackChunkName: "pages/blog/grid/_type" */))
const _b06e6b3e = () => interopDefault(import('../pages/blog/masonry/_type.vue' /* webpackChunkName: "pages/blog/masonry/_type" */))
const _1ac198a2 = () => interopDefault(import('../pages/brands/sidebar/_type.vue' /* webpackChunkName: "pages/brands/sidebar/_type" */))
const _f38c929e = () => interopDefault(import('../pages/pages/places/_slug.vue' /* webpackChunkName: "pages/pages/places/_slug" */))
const _c1e2a264 = () => interopDefault(import('../pages/product/centered/_slug.vue' /* webpackChunkName: "pages/product/centered/_slug" */))
const _19ccb7ad = () => interopDefault(import('../pages/product/default/_slug.vue' /* webpackChunkName: "pages/product/default/_slug" */))
const _0fffa213 = () => interopDefault(import('../pages/product/extended/_slug.vue' /* webpackChunkName: "pages/product/extended/_slug" */))
const _0dfee843 = () => interopDefault(import('../pages/product/fullwidth/_slug.vue' /* webpackChunkName: "pages/product/fullwidth/_slug" */))
const _f8c01104 = () => interopDefault(import('../pages/product/gallery/_slug.vue' /* webpackChunkName: "pages/product/gallery/_slug" */))
const _3660aa9e = () => interopDefault(import('../pages/product/masonry/_slug.vue' /* webpackChunkName: "pages/product/masonry/_slug" */))
const _08b8f1f0 = () => interopDefault(import('../pages/product/sidebar/_slug.vue' /* webpackChunkName: "pages/product/sidebar/_slug" */))
const _09213083 = () => interopDefault(import('../pages/product/sticky/_slug.vue' /* webpackChunkName: "pages/product/sticky/_slug" */))
const _dddd142a = () => interopDefault(import('../pages/shop/nosidebar/_type.vue' /* webpackChunkName: "pages/shop/nosidebar/_type" */))
const _fa940a08 = () => interopDefault(import('../pages/shop/search/_type.vue' /* webpackChunkName: "pages/shop/search/_type" */))
const _93d0b5e8 = () => interopDefault(import('../pages/shop/sidebar/_type.vue' /* webpackChunkName: "pages/shop/sidebar/_type" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/elements",
    component: _67edcf79,
    meta: {},
    alias: ["/amp/elements"],
    name: "elements___ar"
  }, {
    path: "/ProductCard",
    component: _bfc16e60,
    meta: {},
    alias: ["/amp/ProductCard"],
    name: "ProductCard___ar"
  }, {
    path: "/test",
    component: _0ce079de,
    meta: {},
    alias: ["/amp/test"],
    name: "test___ar"
  }, {
    path: "/test2",
    component: _38804871,
    meta: {},
    alias: ["/amp/test2"],
    name: "test2___ar"
  }, {
    path: "/blog/blogResult",
    component: _ad5a8786,
    meta: {},
    alias: ["/amp/blog/blogResult"],
    name: "blog-blogResult___ar"
  }, {
    path: "/blog/classic",
    component: _21a40924,
    meta: {},
    alias: ["/amp/blog/classic"],
    name: "blog-classic___ar"
  }, {
    path: "/blog/filteredAge",
    component: _5862c3fa,
    meta: {},
    alias: ["/amp/blog/filteredAge"],
    name: "blog-filteredAge___ar"
  }, {
    path: "/blog/grid-sidebar",
    component: _77982933,
    meta: {},
    alias: ["/amp/blog/grid-sidebar"],
    name: "blog-grid-sidebar___ar"
  }, {
    path: "/blog/listing",
    component: _4d454216,
    meta: {},
    alias: ["/amp/blog/listing"],
    name: "blog-listing___ar"
  }, {
    path: "/blog/masonry-sidebar",
    component: _03c49d66,
    meta: {},
    alias: ["/amp/blog/masonry-sidebar"],
    name: "blog-masonry-sidebar___ar"
  }, {
    path: "/elements/accordions",
    component: _272cad94,
    meta: {},
    alias: ["/amp/elements/accordions"],
    name: "elements-accordions___ar"
  }, {
    path: "/elements/banners",
    component: _bc07f9a4,
    meta: {},
    alias: ["/amp/elements/banners"],
    name: "elements-banners___ar"
  }, {
    path: "/elements/blog-posts",
    component: _ed4abe5e,
    meta: {},
    alias: ["/amp/elements/blog-posts"],
    name: "elements-blog-posts___ar"
  }, {
    path: "/elements/buttons",
    component: _aa752230,
    meta: {},
    alias: ["/amp/elements/buttons"],
    name: "elements-buttons___ar"
  }, {
    path: "/elements/categories",
    component: _0c9ca1f6,
    meta: {},
    alias: ["/amp/elements/categories"],
    name: "elements-categories___ar"
  }, {
    path: "/elements/cta",
    component: _68c15d12,
    meta: {},
    alias: ["/amp/elements/cta"],
    name: "elements-cta___ar"
  }, {
    path: "/elements/icon-boxes",
    component: _393931ce,
    meta: {},
    alias: ["/amp/elements/icon-boxes"],
    name: "elements-icon-boxes___ar"
  }, {
    path: "/elements/portfolio",
    component: _34953d22,
    meta: {},
    alias: ["/amp/elements/portfolio"],
    name: "elements-portfolio___ar"
  }, {
    path: "/elements/products",
    component: _7ffe368d,
    meta: {},
    alias: ["/amp/elements/products"],
    name: "elements-products___ar"
  }, {
    path: "/elements/tabs",
    component: _b2ee27b2,
    meta: {},
    alias: ["/amp/elements/tabs"],
    name: "elements-tabs___ar"
  }, {
    path: "/elements/testimonials",
    component: _0cd97c5d,
    meta: {},
    alias: ["/amp/elements/testimonials"],
    name: "elements-testimonials___ar"
  }, {
    path: "/elements/titles",
    component: _033bcb04,
    meta: {},
    alias: ["/amp/elements/titles"],
    name: "elements-titles___ar"
  }, {
    path: "/elements/typography",
    component: _56751c98,
    meta: {},
    alias: ["/amp/elements/typography"],
    name: "elements-typography___ar"
  }, {
    path: "/elements/video-banners",
    component: _b64e5cc8,
    meta: {},
    alias: ["/amp/elements/video-banners"],
    name: "elements-video-banners___ar"
  }, {
    path: "/pages/404",
    component: _79f04804,
    meta: {},
    alias: ["/amp/pages/404"],
    name: "pages-404___ar"
  }, {
    path: "/pages/about",
    component: _247eb053,
    meta: {},
    alias: ["/amp/pages/about"],
    name: "pages-about___ar"
  }, {
    path: "/pages/about-2",
    component: _0bfe4e90,
    meta: {},
    alias: ["/amp/pages/about-2"],
    name: "pages-about-2___ar"
  }, {
    path: "/pages/aboutAlf",
    component: _63f73a10,
    meta: {},
    alias: ["/amp/pages/aboutAlf"],
    name: "pages-aboutAlf___ar"
  }, {
    path: "/pages/activate",
    component: _a174f2c6,
    meta: {},
    alias: ["/amp/pages/activate"],
    name: "pages-activate___ar"
  }, {
    path: "/pages/checkForgetPassword",
    component: _70691cd4,
    meta: {},
    alias: ["/amp/pages/checkForgetPassword"],
    name: "pages-checkForgetPassword___ar"
  }, {
    path: "/pages/coming-soon",
    component: _5445320d,
    meta: {},
    alias: ["/amp/pages/coming-soon"],
    name: "pages-coming-soon___ar"
  }, {
    path: "/pages/contact",
    component: _0210bbe6,
    meta: {},
    alias: ["/amp/pages/contact"],
    name: "pages-contact___ar"
  }, {
    path: "/pages/contact-2",
    component: _898368ea,
    meta: {},
    alias: ["/amp/pages/contact-2"],
    name: "pages-contact-2___ar"
  }, {
    path: "/pages/faq",
    component: _6ef87edc,
    meta: {},
    alias: ["/amp/pages/faq"],
    name: "pages-faq___ar"
  }, {
    path: "/pages/forgetPassword",
    component: _d9fe455c,
    meta: {},
    alias: ["/amp/pages/forgetPassword"],
    name: "pages-forgetPassword___ar"
  }, {
    path: "/pages/info",
    component: _20bcc34a,
    meta: {},
    alias: ["/amp/pages/info"],
    name: "pages-info___ar"
  }, {
    path: "/pages/login",
    component: _0dde2e2f,
    meta: {},
    alias: ["/amp/pages/login"],
    name: "pages-login___ar"
  }, {
    path: "/pages/new",
    component: _1d524866,
    meta: {},
    alias: ["/amp/pages/new"],
    name: "pages-new___ar"
  }, {
    path: "/pages/places",
    component: _f01f500e,
    meta: {},
    alias: ["/amp/pages/places"],
    name: "pages-places___ar"
  }, {
    path: "/pages/profile",
    component: _0a3834ef,
    meta: {},
    alias: ["/amp/pages/profile"],
    name: "pages-profile___ar"
  }, {
    path: "/pages/resetPassword",
    component: _322d7550,
    meta: {},
    alias: ["/amp/pages/resetPassword"],
    name: "pages-resetPassword___ar"
  }, {
    path: "/shop/Card",
    component: _65e0983a,
    meta: {},
    alias: ["/amp/shop/Card"],
    name: "shop-Card___ar"
  }, {
    path: "/shop/cart",
    component: _62e7ac2a,
    meta: {},
    alias: ["/amp/shop/cart"],
    name: "shop-cart___ar"
  }, {
    path: "/shop/checkout",
    component: _32c27250,
    meta: {},
    alias: ["/amp/shop/checkout"],
    name: "shop-checkout___ar"
  }, {
    path: "/shop/checkout2",
    component: _25a38112,
    meta: {},
    alias: ["/amp/shop/checkout2"],
    name: "shop-checkout2___ar"
  }, {
    path: "/shop/complete",
    component: _79b9b67a,
    meta: {},
    alias: ["/amp/shop/complete"],
    name: "shop-complete___ar"
  }, {
    path: "/shop/dashboard",
    component: _6f2e5bfa,
    meta: {},
    alias: ["/amp/shop/dashboard"],
    name: "shop-dashboard___ar"
  }, {
    path: "/shop/market",
    component: _541202c6,
    meta: {},
    alias: ["/amp/shop/market"],
    name: "shop-market___ar"
  }, {
    path: "/shop/payment",
    component: _780b1428,
    meta: {},
    alias: ["/amp/shop/payment"],
    name: "shop-payment___ar"
  }, {
    path: "/shop/products2",
    component: _09f6dfb4,
    meta: {},
    alias: ["/amp/shop/products2"],
    name: "shop-products2___ar"
  }, {
    path: "/shop/productSearch",
    component: _2f98a05d,
    meta: {},
    alias: ["/amp/shop/productSearch"],
    name: "shop-productSearch___ar"
  }, {
    path: "/shop/wishlist",
    component: _6c16f962,
    meta: {},
    alias: ["/amp/shop/wishlist"],
    name: "shop-wishlist___ar"
  }, {
    path: "/blog/mask/grid",
    component: _66296f1b,
    meta: {},
    alias: ["/amp/blog/mask/grid"],
    name: "blog-mask-grid___ar"
  }, {
    path: "/blog/mask/masonry",
    component: _6869fd00,
    meta: {},
    alias: ["/amp/blog/mask/masonry"],
    name: "blog-mask-masonry___ar"
  }, {
    path: "/brands/sidebar/advancedBrand",
    component: _2562f20e,
    meta: {},
    alias: ["/amp/brands/sidebar/advancedBrand"],
    name: "brands-sidebar-advancedBrand___ar"
  }, {
    path: "/pages/account/address",
    component: _1c8a26d8,
    meta: {},
    alias: ["/amp/pages/account/address"],
    name: "pages-account-address___ar"
  }, {
    path: "/pages/account/changePass",
    component: _6195c82d,
    meta: {},
    alias: ["/amp/pages/account/changePass"],
    name: "pages-account-changePass___ar"
  }, {
    path: "/pages/account/orders",
    component: _3160a19e,
    meta: {},
    alias: ["/amp/pages/account/orders"],
    name: "pages-account-orders___ar"
  }, {
    path: "/pages/account/profile",
    component: _7939320d,
    meta: {},
    alias: ["/amp/pages/account/profile"],
    name: "pages-account-profile___ar"
  }, {
    path: "/pages/account/trackOrder",
    component: _9af09fe2,
    meta: {},
    alias: ["/amp/pages/account/trackOrder"],
    name: "pages-account-trackOrder___ar"
  }, {
    path: "/pages/info/location",
    component: _1478a23c,
    meta: {},
    alias: ["/amp/pages/info/location"],
    name: "pages-info-location___ar"
  }, {
    path: "/pages/info/socialProfile",
    component: _070655f6,
    meta: {},
    alias: ["/amp/pages/info/socialProfile"],
    name: "pages-info-socialProfile___ar"
  }, {
    path: "/pages/places/advancedSearchResult",
    component: _5d52b6d0,
    meta: {},
    alias: ["/amp/pages/places/advancedSearchResult"],
    name: "pages-places-advancedSearchResult___ar"
  }, {
    path: "/pages/places/findPlace",
    component: _5a9a1075,
    meta: {},
    alias: ["/amp/pages/places/findPlace"],
    name: "pages-places-findPlace___ar"
  }, {
    path: "/pages/places/placesResult",
    component: _8bb8d59c,
    meta: {},
    alias: ["/amp/pages/places/placesResult"],
    name: "pages-places-placesResult___ar"
  }, {
    path: "/shop/category/boxed",
    component: _36d21563,
    meta: {},
    alias: ["/amp/shop/category/boxed"],
    name: "shop-category-boxed___ar"
  }, {
    path: "/shop/category/fullwidth",
    component: _7f07e750,
    meta: {},
    alias: ["/amp/shop/category/fullwidth"],
    name: "shop-category-fullwidth___ar"
  }, {
    path: "/shop/sidebar/advancedAttr",
    component: _48ccfb10,
    meta: {},
    alias: ["/amp/shop/sidebar/advancedAttr"],
    name: "shop-sidebar-advancedAttr___ar"
  }, {
    path: "/shop/sidebar/advancedAttrAfterCategory",
    component: _4f46aa0c,
    meta: {},
    alias: ["/amp/shop/sidebar/advancedAttrAfterCategory"],
    name: "shop-sidebar-advancedAttrAfterCategory___ar"
  }, {
    path: "/shop/sidebar/advancedBrand",
    component: _b77a1710,
    meta: {},
    alias: ["/amp/shop/sidebar/advancedBrand"],
    name: "shop-sidebar-advancedBrand___ar"
  }, {
    path: "/shop/sidebar/advancedProducts",
    component: _1eaf2583,
    meta: {},
    alias: ["/amp/shop/sidebar/advancedProducts"],
    name: "shop-sidebar-advancedProducts___ar"
  }, {
    path: "/shop/sidebar/advancedSubCategory",
    component: _6e5e03e2,
    meta: {},
    alias: ["/amp/shop/sidebar/advancedSubCategory"],
    name: "shop-sidebar-advancedSubCategory___ar"
  }, {
    path: "/shop/sidebar/shopFilter",
    component: _afa6a0aa,
    meta: {},
    alias: ["/amp/shop/sidebar/shopFilter"],
    name: "shop-sidebar-shopFilter___ar"
  }, {
    path: "/",
    component: _513e5a63,
    meta: {},
    alias: ["/amp/"],
    name: "index___ar"
  }, {
    path: "/blog/single/default/:slug?",
    component: _7b677413,
    meta: {},
    alias: ["/amp/blog/single/default/:slug?"],
    name: "blog-single-default-slug___ar"
  }, {
    path: "/blog/single/fullwidth/:slug?",
    component: _73dc2329,
    meta: {},
    alias: ["/amp/blog/single/fullwidth/:slug?"],
    name: "blog-single-fullwidth-slug___ar"
  }, {
    path: "/blog/single/sidebar/:slug?",
    component: _5d3e436e,
    meta: {},
    alias: ["/amp/blog/single/sidebar/:slug?"],
    name: "blog-single-sidebar-slug___ar"
  }, {
    path: "/blog/grid/:type?",
    component: _1eb6e56e,
    meta: {},
    alias: ["/amp/blog/grid/:type?"],
    name: "blog-grid-type___ar"
  }, {
    path: "/blog/masonry/:type?",
    component: _b06e6b3e,
    meta: {},
    alias: ["/amp/blog/masonry/:type?"],
    name: "blog-masonry-type___ar"
  }, {
    path: "/brands/sidebar/:type?",
    component: _1ac198a2,
    meta: {},
    alias: ["/amp/brands/sidebar/:type?"],
    name: "brands-sidebar-type___ar"
  }, {
    path: "/pages/places/:slug",
    component: _f38c929e,
    meta: {},
    alias: ["/amp/pages/places/:slug"],
    name: "pages-places-slug___ar"
  }, {
    path: "/product/centered/:slug?",
    component: _c1e2a264,
    meta: {},
    alias: ["/amp/product/centered/:slug?"],
    name: "product-centered-slug___ar"
  }, {
    path: "/product/default/:slug?",
    component: _19ccb7ad,
    meta: {},
    alias: ["/amp/product/default/:slug?"],
    name: "product-default-slug___ar"
  }, {
    path: "/product/extended/:slug?",
    component: _0fffa213,
    meta: {},
    alias: ["/amp/product/extended/:slug?"],
    name: "product-extended-slug___ar"
  }, {
    path: "/product/fullwidth/:slug?",
    component: _0dfee843,
    meta: {},
    alias: ["/amp/product/fullwidth/:slug?"],
    name: "product-fullwidth-slug___ar"
  }, {
    path: "/product/gallery/:slug?",
    component: _f8c01104,
    meta: {},
    alias: ["/amp/product/gallery/:slug?"],
    name: "product-gallery-slug___ar"
  }, {
    path: "/product/masonry/:slug?",
    component: _3660aa9e,
    meta: {},
    alias: ["/amp/product/masonry/:slug?"],
    name: "product-masonry-slug___ar"
  }, {
    path: "/product/sidebar/:slug?",
    component: _08b8f1f0,
    meta: {},
    alias: ["/amp/product/sidebar/:slug?"],
    name: "product-sidebar-slug___ar"
  }, {
    path: "/product/sticky/:slug?",
    component: _09213083,
    meta: {},
    alias: ["/amp/product/sticky/:slug?"],
    name: "product-sticky-slug___ar"
  }, {
    path: "/shop/nosidebar/:type?",
    component: _dddd142a,
    meta: {},
    alias: ["/amp/shop/nosidebar/:type?"],
    name: "shop-nosidebar-type___ar"
  }, {
    path: "/shop/search/:type?",
    component: _fa940a08,
    meta: {},
    alias: ["/amp/shop/search/:type?"],
    name: "shop-search-type___ar"
  }, {
    path: "/shop/sidebar/:type?",
    component: _93d0b5e8,
    meta: {},
    alias: ["/amp/shop/sidebar/:type?"],
    name: "shop-sidebar-type___ar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
