import { render, staticRenderFns } from "./fifthsecond.vue?vue&type=template&id=3cb948f0&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/royal_demo/Demo-4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3cb948f0')) {
      api.createRecord('3cb948f0', component.options)
    } else {
      api.reload('3cb948f0', component.options)
    }
    module.hot.accept("./fifthsecond.vue?vue&type=template&id=3cb948f0&", function () {
      api.rerender('3cb948f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/alf/homeComponents/fifth/fifthsecond.vue"
export default component.exports