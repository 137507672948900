import { render, staticRenderFns } from "./BlogSidebar.vue?vue&type=template&id=8a29c454&scoped=true&"
import script from "./BlogSidebar.vue?vue&type=script&lang=js&"
export * from "./BlogSidebar.vue?vue&type=script&lang=js&"
import style0 from "./BlogSidebar.vue?vue&type=style&index=0&id=8a29c454&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a29c454",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/royal_demo/Demo-4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8a29c454')) {
      api.createRecord('8a29c454', component.options)
    } else {
      api.reload('8a29c454', component.options)
    }
    module.hot.accept("./BlogSidebar.vue?vue&type=template&id=8a29c454&scoped=true&", function () {
      api.rerender('8a29c454', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/partial/blog/BlogSidebar.vue"
export default component.exports