import { render, staticRenderFns } from "./fourthfifth.vue?vue&type=template&id=be10307a&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/royal_demo/Demo-4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('be10307a')) {
      api.createRecord('be10307a', component.options)
    } else {
      api.reload('be10307a', component.options)
    }
    module.hot.accept("./fourthfifth.vue?vue&type=template&id=be10307a&", function () {
      api.rerender('be10307a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/alf/homeComponents/fourth/fourthfifth.vue"
export default component.exports